export var MODE = process.env.NODE_ENV;
export var APP_NAME = 'C2C';
export var SOCKET = MODE === 'development' ? 'wss://ff-c2c-dev.trafficwave.kz/api/ws' : 'wss://c2c.bankffin.kz/api/ws';
export var SERVER = MODE === 'development' ? 'https://ff-c2c-dev.trafficwave.kz/api' : 'https://c2c.bankffin.kz/api';
export var MORTGAGE_URL = MODE === 'development' ? 'https://new-dev-ipoteka.trafficwave.kz/' : 'https://ipoteka.bankffin.kz/';
export default {
    MODE: MODE,
    APP_NAME: APP_NAME,
    SOCKET: SOCKET,
    SERVER: SERVER,
    MORTGAGE_URL: MORTGAGE_URL,
};
