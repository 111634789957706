var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { createContext, useCallback, useContext, useEffect, useState, } from "react";
import { useAuthorization } from "@/saga-query/query-hooks/useAuthorization";
import { useNavigate, useParams } from "react-router-dom";
import { useNotification } from "@/saga-query/query-hooks/useNotification";
import { useMutation } from "@/saga-query/query-hooks/useMutation";
import { Api } from "@/types/api";
import { conditionsHead, conditionsHeader, infoCadastralData, infoData, texts } from "./data";
import { parseParams } from "@/helpers/parseParams";
import { useProgress } from "@/hooks/useProgress";
import { ThemeContext } from "@/containers/theme-container";
import { useQueryCache } from "@/saga-query/query-hooks/useQueryCache";
import { useTranslation } from "react-i18next";
import { clientStepsNotary, sellerStepsNotary, spouseStepsNotary } from "@/options/steps/notary";
import { clientStepsRegistrar, sellerStepsRegistrar, spouseStepsRegistrar } from "@/options/steps/registrar";
import { getStepByStatus } from "@/options/steps/steps";
export var InitContext = createContext({});
var isDisabledFlow = true;
export var InitContextProvider = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var children = _a.children, options = _a.options;
    var t = useTranslation().t;
    var uuid = useParams().uuid;
    var get = useQueryCache().get;
    var order = get([Api.LOAD_APPLICATION_BY_UUID, uuid]).data;
    var themeData = useContext(ThemeContext);
    var _h = useAuthorization(), profile = _h.profile, isAuth = _h.isAuth, onRecover = _h.onRecover, onVerifySuccess = _h.onVerifySuccess;
    var navigate = useNavigate();
    var _j = useState({}), verifyData = _j[0], setVerifyData = _j[1];
    var _k = useState(getStepByStatus('NEW', options === null || options === void 0 ? void 0 : options.type)), step = _k[0], set_step = _k[1];
    var _l = React.useState({ 'a': true, 'b': true, 'c': true, 'd': true }), checkBoxes = _l[0], setCheckBoxes = _l[1];
    var _m = useNotification(), onSuccess = _m.onSuccess, onWarn = _m.onWarn;
    var _o = useState(((_b = window.location.search) === null || _b === void 0 ? void 0 : _b.length) > 0 ? parseParams(window.location.search) : (_c = order === null || order === void 0 ? void 0 : order.client) === null || _c === void 0 ? void 0 : _c.user), params = _o[0], setParams = _o[1];
    // const [params, setParams] = useState<Record<string, string>>(window.location.search?.length > 0 ? Object.assign({registrar_choice: 'NOTARY'}, parseParams(window.location.search)) : Object.assign({registrar_choice: 'NOTARY'}, order?.client?.user))
    var progressProps = useProgress('INIT', function () { return navigate("/steps/".concat(verifyData === null || verifyData === void 0 ? void 0 : verifyData.application_id)); });
    useEffect(function () {
        var _a, _b, _c;
        if (((_a = window.location.search) === null || _a === void 0 ? void 0 : _a.length) > 0)
            setParams(parseParams(window.location.search));
        else if ((_b = order === null || order === void 0 ? void 0 : order.client) === null || _b === void 0 ? void 0 : _b.user)
            setParams((_c = order === null || order === void 0 ? void 0 : order.client) === null || _c === void 0 ? void 0 : _c.user);
    }, [(_e = (_d = order === null || order === void 0 ? void 0 : order.client) === null || _d === void 0 ? void 0 : _d.user) === null || _e === void 0 ? void 0 : _e.phone]);
    useEffect(function () {
        if (parseParams(window.location.search).is_integration !== "true")
            setParams(function (prev) { return (__assign(__assign({}, prev), { seller_iin: '' })); });
    }, [(_f = window.location.search) === null || _f === void 0 ? void 0 : _f.length]);
    var setStep = function (status) { return set_step(getStepByStatus(status, options === null || options === void 0 ? void 0 : options.type)); };
    var changeParam = function (key) { return function (value) {
        if (['iin', 'phone'].includes(key)) {
            return setParams(function (prev) {
                var _a;
                return (__assign(__assign({}, prev), (_a = {}, _a[key] = value.replace(/[^+\d]/g, ''), _a)));
            });
        }
        setParams(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[key] = value, _a)));
        });
    }; };
    var _p = useMutation({
        api: "/applications/new/",
        method: 'POST',
        meta: { authorization: false },
        type: Api.POST_APPLICATIONS_NEW,
        showErrorMessage: true,
        onSuccess: function () {
            onSuccess({ title: '', message: 'Введите код по СМС' });
            setStep('CLIENT_OTP_VERIFICATION');
        }
    }), onNew = _p[0], isLoadingOnNew = _p[1].isLoading;
    var onSuccessVerify = function (data) {
        progressProps.setStart();
        setVerifyData(data);
        onVerifySuccess(data);
        setTimeout(progressProps.setDone, 3000);
    };
    var _q = useMutation({
        api: "/applications/clients/otp-verify/",
        method: 'POST',
        type: Api.POST_APPLICATIONS_VERIFY,
        meta: { authorization: false },
        showErrorMessage: true,
        onSuccess: onSuccessVerify
    }), onClientsVerify = _q[0], isLoadingOnClientsVerify = _q[1].isLoading;
    var _r = useMutation({
        api: "/applications/sellers/otp-verify/",
        method: 'POST',
        type: Api.POST_APPLICATIONS_VERIFY,
        meta: { authorization: false },
        showErrorMessage: true,
        onSuccess: onSuccessVerify
    }), onSellersVerify = _r[0], isLoadingOnSellersVerify = _r[1].isLoading;
    var _s = useMutation({
        api: "/applications/".concat(params === null || params === void 0 ? void 0 : params.uuid, "/sellers/confirm-real-estate/"),
        data: { iin: ((_g = params === null || params === void 0 ? void 0 : params.seller_iin) === null || _g === void 0 ? void 0 : _g.replace(/\s+/g, '')) || '' },
        method: 'POST',
        type: Api.POST_APPLICATIONS_VERIFY,
        meta: { authorization: false },
        showErrorMessage: true,
        onSuccess: onSuccessVerify
    }), onSellersConfirm = _s[0], isLoadingOnSellersConfirm = _s[1].isLoading;
    var _t = useMutation({
        api: "/applications/".concat(params === null || params === void 0 ? void 0 : params.uuid, "/spouses/confirm-real-estate/"),
        method: 'POST',
        type: Api.POST_APPLICATIONS_VERIFY,
        meta: { authorization: false },
        showErrorMessage: true,
        onSuccess: onSuccessVerify
    }), onSpousesConfirm = _t[0], isLoadingOnSpousesConfirm = _t[1].isLoading;
    var _u = useMutation({
        api: "/applications/spouses/otp-verify/",
        method: 'POST',
        type: Api.POST_APPLICATIONS_VERIFY,
        meta: { authorization: false },
        showErrorMessage: true,
        onSuccess: onSuccessVerify
    }), onSpousesVerify = _u[0], isLoadingOnSpousesVerify = _u[1].isLoading;
    var _v = useState({
        type: null,
        isOpen: false,
        title: '',
        description: '',
    }), modalData = _v[0], setModalData = _v[1];
    var getAcceptFunctionByType = function (type) {
        var fnData = {
            'CONFIRMATION': function () {
            },
        };
        return fnData[type] || console.log;
    };
    var inputsList = [
        {
            type: 'INPUT',
            value: (params === null || params === void 0 ? void 0 : params['seller_iin']) || '',
            onChange: changeParam('seller_iin'),
            placeholder: t('flow.input.enter_iin'),
            mask: '99 99 99 99 99 99',
            label: t('flow.input.iin'),
            className: 'mortgage-info-form__select'
        },
    ];
    var prepareData = {
        CLIENT: {
            onApply: function () { return onNew(params); },
            onResend: function (fn) {
                onNew(params, { onSuccess: fn });
            },
            onVerify: function () { return onClientsVerify(params); },
        },
        SELLER: {
            texts: texts(params === null || params === void 0 ? void 0 : params.client_iin),
            infoData: (params === null || params === void 0 ? void 0 : params.address_choice) !== 'FROM_CADASTRAL' ? infoData : infoCadastralData,
            conditionsHeader: conditionsHeader,
            conditionsHead: conditionsHead,
            onApply: function () {
                onSellersConfirm({}, { onSuccess: function () { return setStep('CLIENT_OTP_VERIFICATION'); } });
            },
            onResend: function (fn) {
                onSellersConfirm({}, { onSuccess: function () {
                        setStep('CLIENT_OTP_VERIFICATION');
                        fn();
                    } });
            },
            onVerify: function () { return onSellersVerify({ phone: params.phone, code: params.code, }); },
        },
        SPOUSE: {
            texts: texts(params === null || params === void 0 ? void 0 : params.client_iin),
            infoData: (params === null || params === void 0 ? void 0 : params.address_choice) !== 'FROM_CADASTRAL' ? infoData : infoCadastralData,
            conditionsHeader: conditionsHeader,
            conditionsHead: conditionsHead,
            onApply: function () {
                onSpousesConfirm({}, { onSuccess: function () { return setStep('CLIENT_OTP_VERIFICATION'); } });
            },
            onResend: function (fn) {
                onSpousesConfirm({}, { onSuccess: function () {
                        setStep('CLIENT_OTP_VERIFICATION');
                        fn();
                    } });
            },
            onVerify: function () { return onSpousesVerify({ phone: params.phone, code: params.code, }); },
        },
    };
    var isDisabledModalFooterByType = function (type) {
        var ACCEPT = false;
        switch (type) {
            case 'CONFIRMATION':
                ACCEPT = false;
                break;
        }
        return { ACCEPT: ACCEPT };
    };
    var onModalClose = function () {
        setModalData(__assign(__assign({}, modalData), { isOpen: false }));
    };
    var getStepsByType = useCallback(function (type, registrar_choice) {
        var data = {
            CLIENT: registrar_choice === 'NOTARY' ? clientStepsNotary : clientStepsRegistrar,
            SELLER: registrar_choice === 'NOTARY' ? sellerStepsNotary : sellerStepsRegistrar,
            CLIENT_SPOUSE: registrar_choice === 'NOTARY' ? spouseStepsNotary : spouseStepsRegistrar,
            SELLER_SPOUSE: registrar_choice === 'NOTARY' ? spouseStepsNotary : spouseStepsRegistrar,
        };
        return data[type] || Array(10).fill({ step: 0, component: 'DEFAULT' });
    }, []);
    var startProgressByStatus = function (order, data) {
        var currentProgress = data.find(function (data) { return data[0]; });
        if (Array.isArray(currentProgress))
            progressProps.setStart(currentProgress[1]);
        else
            progressProps.setDone();
    };
    var progressItem = function (cond, text) { return [cond, text]; };
    useEffect(function () {
        startProgressByStatus(order, [
            progressItem(isDisabledFlow, t('Услуга временно недоступна'))
        ]);
    }, [order === null || order === void 0 ? void 0 : order.status]);
    var showSideBarSteps = true;
    var steps = getStepsByType(options === null || options === void 0 ? void 0 : options.type, params === null || params === void 0 ? void 0 : params.registrar_choice);
    var stepIndex = steps.findIndex(function (s) { return s.step == step; });
    var percent = ((stepIndex + 1) / (steps === null || steps === void 0 ? void 0 : steps.length)) * 100;
    return (React.createElement(InitContext.Provider, { value: {
            themeData: themeData,
            registrar_choice: params === null || params === void 0 ? void 0 : params.registrar_choice,
            isLoading: {
                onApply: isLoadingOnNew || isLoadingOnSpousesConfirm || isLoadingOnSellersConfirm,
                onVerify: isLoadingOnClientsVerify || isLoadingOnSellersVerify || isLoadingOnSpousesVerify,
            },
            isDisabled: {
                onApply: !Object.entries(checkBoxes).every(function (_a) {
                    var _ = _a[0], val = _a[1];
                    return val;
                }) || isLoadingOnNew || isLoadingOnSpousesConfirm || isLoadingOnSellersConfirm,
                onVerify: isLoadingOnClientsVerify || isLoadingOnSellersVerify || isLoadingOnSpousesVerify,
            },
            showElem: {},
            profile: profile,
            step: step,
            steps: steps,
            showSideBarSteps: showSideBarSteps,
            percent: percent,
            progressProps: progressProps,
            step1Props: __assign(__assign({ params: params, changeParam: changeParam, checkBoxes: checkBoxes, setModalData: setModalData, inputsList: inputsList, setCheckBoxes: setCheckBoxes }, prepareData[(options === null || options === void 0 ? void 0 : options.type) || (order === null || order === void 0 ? void 0 : order.user_role)]), { setPrevStep: function () { return setStep('NEW'); } }),
            modalParams: {
                modalData: modalData,
                isDisabled: isDisabledModalFooterByType(modalData === null || modalData === void 0 ? void 0 : modalData.type),
                onAccept: getAcceptFunctionByType(modalData === null || modalData === void 0 ? void 0 : modalData.type),
                modalFooterData: [],
                onModalClose: onModalClose,
                t: t
            }
        } }, children));
};
